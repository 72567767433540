import React from 'react';
import './BlogEntryNotFound.scss';

const BlogEntryNotFound = props => (
	<div className="blogEntryNotFound">
		BlogEntryNotFound
	</div>
)

export default BlogEntryNotFound;
